<template>
	<div>
		<Header :title="isEdit ? '修改广告图' : '新增广告图'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">

				<a-form-item label="标题" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.name" placeholder="请输入标题"></a-input>
				</a-form-item>

				<a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
					<a-input-number style="width: 200px;" min="0" :precision="0" v-model:value="modelRef.sort"
						placeholder="请输入优先级"></a-input-number>
				</a-form-item>

				<a-form-item label="图片" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-upload v-model:file-list="fileList" action="/admin/ajaxUpload.do" list-type="picture-card"
						accept="image/*" @change="handleChange">
						<div v-if="fileList.length < 1">
							<Icon icon="PlusOutlined"></Icon>
							<div style="margin-top: 8px">Upload</div>
						</div>
					</a-upload>
					<div style="color: #c2c2c2" v-if="[1, 3, 5, 6, 7].includes(modelRef.showPosition)">建议图片比例：690 : 150
					</div>
					<div style="color: #c2c2c2" v-if="[4].includes(modelRef.showPosition)">建议图片比例：690 : 260</div>
					<div style="color: #c2c2c2" v-if="[2, 8].includes(modelRef.showPosition)">建议图片比例：690 : 300</div>
				</a-form-item>

				<a-form-item label="是否启用" name="isDisabled">
					<a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
				</a-form-item>

				<a-form-item name="showPosition" label="展示位置" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-select v-model:value="modelRef.showPosition" style="width: 180px;" placeholder="请选择展示位置" @change="listChange">
						<a-select-option :value="1">电影 - 选座</a-select-option>
						<a-select-option :value="2">电影 - 电影页</a-select-option>
						<a-select-option :value="3">演出 - 首页</a-select-option>
						<a-select-option :value="4">商城 - 首页</a-select-option>
						<a-select-option :value="5">个人中心 - 主页</a-select-option>
						<a-select-option :value="6">个人中心 - 一卡通充值</a-select-option>
						<a-select-option :value="7">个人中心 - 绑定卡券</a-select-option>
						<a-select-option :value="8">小程序首页</a-select-option>
						<a-select-option :value="11">用于后台配置选择</a-select-option>
						<a-select-option :value="12">演出 - 抢票动向</a-select-option>
						<a-select-option :value="13">演出 - 福利站</a-select-option>
						<a-select-option :value="14">影院定制服务</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item name="jumpPosition" label="跳转位置" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-select v-model:value="modelRef.jumpPosition" style="width: 180px;" placeholder="请选择跳转位置"
						@change="listChange">
						<a-select-option :value="0">不进行跳转</a-select-option>
						<a-select-option :value="1">特色会员</a-select-option>
						<a-select-option :value="2">演出详情</a-select-option>
						<a-select-option :value="3">抢票动向</a-select-option>
						<a-select-option :value="4">福利站</a-select-option>
						<a-select-option :value="5">演出攻略</a-select-option>
						<a-select-option :value="6">商品详情</a-select-option>
						<a-select-option :value="7">热卖专场</a-select-option>
						<a-select-option :value="8">影片详情</a-select-option>
						<a-select-option :value="9">影院详情</a-select-option>
						<a-select-option :value="10">卡券礼包主页</a-select-option>
						<a-select-option :value="11">一卡通详情</a-select-option>
						<a-select-option :value="12">礼包详情</a-select-option>
						<a-select-option :value="13">活动详情</a-select-option>
						<a-select-option :value="14">活动列表</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item v-if="[2, 6, 7, 8, 9, 11, 12, 13].includes(modelRef.jumpPosition)" label="具体跳转位置"
					:rules="[{required: true, message: '必填项不允许为空'}]" extra="只能选一个具体跳转位置">
					<selectPerform v-model:value="idList" v-if="[2].includes(modelRef.jumpPosition)" type="radio"></selectPerform>
					<selectGoods v-model:value="idList" v-if="[6].includes(modelRef.jumpPosition)" type="radio"></selectGoods>
					<selectHot v-model:value="idList" v-if="[7].includes(modelRef.jumpPosition)" type="radio"></selectHot>
					<selectMovie v-model:value="idList" v-if="[8].includes(modelRef.jumpPosition)" type="radio" :isCinema="false"></selectMovie>
					<selectCinema v-model:value="idList" v-if="[9].includes(modelRef.jumpPosition)" type="radio"></selectCinema>
					<selectOneCard v-model:value="idList" v-if="[11].includes(modelRef.jumpPosition)" type="radio"></selectOneCard>
					<selectGiftPack v-model:value="idList" v-if="[12].includes(modelRef.jumpPosition)" type="radio"></selectGiftPack>
					<selectActivity v-model:value="idList" v-if="[13].includes(modelRef.jumpPosition)" type="radio"></selectActivity>
				</a-form-item>

				<a-row>
					<a-col :span="24" style="margin-top: 20px; text-align: center;">
						<a-button type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>

			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import selectGoods from '@/components/select/selectGoods'
	import selectMovie from '@/components/movie/index.vue'
	import selectPerform from '@/components/select/selectPerform'
	import selectActivity from '@/components/select/selectActivity'
	import selectCinema from '@/components/select/selectCinema'
	import selectGiftPack from '@/components/select/selectGiftPack'
	import selectHot from '@/components/select/selectHot'
	import selectOneCard from '@/components/select/selectOneCard'
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getPlatformAdImageDetail,
		savePlatformAdImage,
		updatePlatformAdImage
	} from "@/service/modules/operation";
	export default {
		components: {
			Header,
			selectGoods,
			selectMovie,
			selectPerform,
			selectActivity,
			selectCinema,
			selectGiftPack,
			selectHot,
			selectOneCard,
			Icon,
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				fileList: [],
				loading: false,
				modelRef: {
					isDisabled: true,
				},
				idList: [],
			}
		},
		created() {
			if (this.isEdit) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPlatformAdImageDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						ret.data.isDisabled = ret.data.isDisabled ? false : true;

						this.modelRef = ret.data;
						this.idList.push(ret.data.jumpId);
					}
					if (ret.data.imageUrl) {
						this.fileList = ret.data.imageUrl.split(',').map(item => {
							return {
								url: item,
							}
						})
					}
				} catch (e) {
					this.loading = false;
				}
			},
			handleChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			async onSubmit() {
				if (this.fileList.length === 0) {
					this.$message.error('图片不能为空！');
					return;
				}
				if ([2, 6, 7, 8, 9, 11, 12, 13].includes(this.modelRef.jumpPosition) && this.idList.length < 1) {
					this.$message.error('请选择一个具体跳转位置！');
					return;
				}
				if ([2, 6, 7, 8, 9, 11, 12, 13].includes(this.modelRef.jumpPosition) && this.idList.length > 1) {
					this.$message.error('只能选择一个具体跳转位置！');
					return;
				}
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.jumpId = this.idList.join();
				postData.isDisabled = postData.isDisabled ? 0 : 1;
				const imgList = [];
				this.fileList.forEach(item => {
					imgList.push(item.url);
				});
				postData.imageUrl = imgList.join(',');
				this.loading = true;
				try {
					let ret;
					if (this.isEdit) {
						postData.id = this.id;
						ret = await updatePlatformAdImage(postData);
					} else {
						ret = await savePlatformAdImage(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch (e) {
					this.loading = false;
				}
			},
			listChange() {
				this.idList = [];
			},
		}
	}
</script>

<style scoped>

</style>